.card {
    box-shadow: 10px 12px 10px rgba(0, 0, 0, .1);
}

.customer-selection {
    border: solid 1px #e6e6e6;
    border-radius: 2px;
    height: 241px;
    overflow: auto;
}

.customer-selection>div {
    border: solid #e6e6e6;
    border-width: 0 0 1px 0;
    padding: 16px;
    padding-left: 32px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}


.customer-selection>div:hover {
    background-color: #fffde7;
}

.customer-selection>div>span {
    font-size: 14px;
    line-height: 1.14;
    color: #616161;
}

.file-list-container {
    max-height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.thin-scrollbar {
    scrollbar-width: thin;
}

.thin-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
    background-color: #787878;
    border-radius: 5px;
}
